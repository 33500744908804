.Header {
  position: fixed;
  top: 30px;
  z-index: 999;
  width: 100%;
}

.haeder-top {
  top: 0px;
}

.Header .scrollheader {
  background: #fff !important;
  transition: all 0.3s ease-in-out;
  margin-bottom: -32px;
}

.Header .header_holder {
  background: transparent
    linear-gradient(
      284deg,
      #ffffff82 0%,
      #ffffff 106%,
      #ffffff 100%,
      #ffffff 100%
    )
    0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 96px #00000029;
  border-radius: 5px;
  padding: 0px 25px;
  transition: all 0.3s ease-in-out;
  backdrop-filter: blur(7px);
}

.Header .white-bg-holder {
  background: #fff;
  box-shadow: 0px 3px 96px #00000029;
  border-radius: 5px;
  padding: 0px 25px;
}

.Header .header_holder .navbar {
  padding: 0px 0px;
}

.Header .header_holder .navbar .navbar-toggler {
  font-size: 17px;
  line-height: 1;
  opacity: 1;
  color: #000;
  border: none;
}

.Header .header_holder .navbar .navbar-toggler:focus {
  box-shadow: none;
}

.Header .header_holder .logo-holder {
  height: 80px;
  width: auto;
}

.Header .header_holder .logo-holder .headlogo {
  width: 100%;
  height: 100%;
}

.Header .header_holder .services-dropdown_holder .services-dropdown {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  padding: 0px 25px !important;
  background: none;
  border: none;
}

/* Default state: Hide the :after pseudo-element */
.Header .header_holder .services-dropdown_holder .services-dropdown:after {
  content: "";
  position: absolute;
  border-bottom: 0px solid #000;
  border-top: none;
  border-right: none;
  border-left: none;
  bottom: -33px;
  left: 0;
  right: 0px;
  width: 40px;
  margin: 0px auto;
}

/* When the dropdown is clicked, show the :after element with styles */
.Header .header_holder .services-dropdown_holder .services-dropdown.order-active:after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #000;
  border-top: none;
  border-right: none;
  border-left: none;
  bottom: -33px;
  left: 0;
  right: 0px;
  width: 40px;
  margin: 0px auto;
}



.Header .header_holder .services-dropdown_holder .dropdown-item {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  text-wrap: initial;
  border-radius: 5px;
  position: relative;
  transition: all 0.5s ease-in-out;
  margin: 2px 0px;
}

.Header .header_holder .services-dropdown_holder .dropdown-item::before {
  background: transparent linear-gradient(100deg, #8e1c79 0%, #470e3d 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  width: 0%;
  content: "";
  position: absolute;
  left: 0px;
  top: 0;
  height: 0%;
  z-index: 0;
  transition: all 0.4s ease-in-out;
}

.Header .header_holder .services-dropdown_holder .dropdown-item:hover::before {
  background: transparent linear-gradient(100deg, #8e1c79 0%, #470e3d 100%) 0%
    0% no-repeat padding-box;
  width: 100%;
  z-index: -1;
  height: 100%;
}

.Header .header_holder .services-dropdown_holder .dropdown-item:hover {
  color: #fff;
  background: none !important;
  padding-left: 8px;
  transition: all 0.4s ease-in-out;
}

.Header .header_holder .services-dropdown_holder .dropdown-menu.show {
  border: none;
  background: #ffffff;
  width: 401px;
  padding: 5px 5px;
  transform: translateY(31px);
}

.Header .header_holder .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  padding: 0px 25px !important;
}

.Header .header_holder .nav-link.active {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  position: relative;
}

.Header .header_holder .nav-link.active::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #000;
  bottom: -33px;
  left: 0;
  right: 0px;
  width: 40px;
  margin: 0px auto;
}

@media (min-width: 0px) and (max-width: 991px) {
  .Header .header_holder .nav-link.active::after {
    display: none;
  }

  .Header .header_holder .nav-link {
    font-size: 14px;
    padding: 10px 25px !important;
  }

  .Header .header_holder .nav-link.active {
    font-size: 14px;
  }

  .Header .header_holder .services-dropdown_holder .services-dropdown {
    font-size: 14px;
    padding: 10px 25px !important;
  }

  .Header .header_holder .services-dropdown_holder .dropdown-menu.show {
    transform: translateY(0px);
    background: #9c27b014;
    width: 100%;
  }
}

@media (min-width: 0px) and (max-width: 280px) {
  .Header .header_holder .logo-holder {
    height: 30px;
  }

  .Header {
    top: 10px;
  }
}

@media (min-width: 281px) and (max-width: 485px) {
  .Header .header_holder .logo-holder {
    height: 30px;
  }

  .Header {
    top: 10px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Header .header_holder .logo-holder {
    height: 30px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Header .header_holder .logo-holder {
    height: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Header .header_holder .logo-holder {
    height: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Header .header_holder .logo-holder {
    height: 50px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Header .header_holder .logo-holder {
    height: 50px;
  }

  .Header .header_holder .nav-link.active::after {
    bottom: -18px;
  }
}
