.scaff-text-section .grey-bgg-main {
  background: #f5f5f5;
  border-radius: 10px;
  padding: 20px 20px;
  margin-bottom: 4%;
}

.scaff-text-section .grey-bgg-main .main-blur-head {
  transform: rotate(-90deg);
}

.scaff-text-section .grey-bgg-main .card-heading-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

.scaff-text-section .grey-bgg-main .main-blur-head .blur-head-title {
  font-size: 35px;
  color: #8e1c79;
  opacity: 0.1;
  font-weight: 700;
  text-wrap: nowrap;
  text-transform: uppercase;
}

.scaff-text-section .white-bggg-scaff .accessories-img {
  margin: auto;
  background: #fff;
  border: 0.6000000238418579px solid #707070;
  border-radius: 11px;
  padding: 15px 20px;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}

.scaff-text-section .grey-bgg-main .heading-scaff {
  color: #8e1c79;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.scaff-text-section .grey-bgg-main .second-seccc-main ul {
  padding-left: 1rem;
  list-style-type: square;
}

.scaff-text-section .grey-bgg-main .second-seccc-main ul li::marker {
  color: #fad8f4;
}

.scaff-text-section .grey-bgg-main .second-seccc-main ul li {
    font-size: 14px;
    text-align: justify;
    margin-bottom: 5px;
    color: #000;
    font-weight: 500;
}

.scaff-text-section .grey-bgg-main .second-seccc-main .content-holder {
  margin-bottom: 20px;
}

.scaff-text-section .grey-bgg-main .second-seccc-main .text-holder p {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  padding-left: 15px;
}

/* Media */
@media (min-width: 0px) and (max-width: 576px) {
  .scaff-text-section .grey-bgg-main .main-blur-head {
    transform: unset;
  }

  .scaff-text-section .grey-bgg-main .main-blur-head .blur-head-title {
    font-size: 25px;
    text-align: center;
    margin-top: 0;
  }

  .scaff-text-section .white-bggg-scaff .accessories-img {
    padding: 25px;
    width: 100%;
    margin: 5% 0;
  }

  .scaff-text-section .grey-bgg-main .heading-scaff {
    font-size: 15px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .scaff-text-section .grey-bgg-main .main-blur-head {
    transform: unset;
  }

  .scaff-text-section .grey-bgg-main .main-blur-head .blur-head-title {
    font-size: 25px;
    text-align: center;
    margin-top: 0;
  }

  .scaff-text-section .white-bggg-scaff .accessories-img {
    padding: 25px;
    width: 100%;
    margin: 5% 0;
  }

  .scaff-text-section .grey-bgg-main .heading-scaff {
    font-size: 15px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .scaff-text-section .grey-bgg-main .main-blur-head .blur-head-title {
    font-size: 25px;
  }

  .scaff-text-section .white-bggg-scaff .accessories-img {
    width: 100%;
  }
}

@media (min-width: 993px) and (max-width: 1024px) {
  .scaff-text-section .white-bggg-scaff .accessories-img {
    width: 80%;
  }

  .scaff-text-section .grey-bgg-main .main-blur-head .blur-head-title {
    font-size: 30px;
  }
}
