.About_values_sec .imgdiv .values-img{
    width: 100%;
    filter: saturate(0.2);
}

.About_values_sec .imgdiv:hover .values-img{
    width: 100%;
    filter: saturate(1);
}

.About_values_sec {
    margin-bottom: 5%;
}