.Your_Inquiries {
  margin-top: 100px;
  margin-bottom: 50px;
}

.Your_Inquiries .Your_Inquiries_holder {
  position: relative;
}

.Your_Inquiries .Your_Inquiries_holder .img-holder {
  overflow: hidden;
  border-radius: 10px;
}

.Your_Inquiries:hover .Your_Inquiries_form_holder {
    border: 0.5px solid #8E1C79;
    transition: all 0.3s ease-in-out;
}

.Your_Inquiries:hover .Your_Inquiries_img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.Your_Inquiries .Your_Inquiries_holder .img-holder .Your_Inquiries_img {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.Your_Inquiries .Your_Inquiries_holder .Your_Inquiries_form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Your_Inquiries
  .Your_Inquiries_holder
  .Your_Inquiries_form_holder
  .heading_holder {
  margin-bottom: 30px;
}

.Your_Inquiries
  .Your_Inquiries_holder
  .Your_Inquiries_form_holder
  .heading_holder
  .heading-text {
  text-align: center;
}

.Your_Inquiries .Your_Inquiries_holder .main-form-holder {
  position: absolute;
  right: 0px;
  width: 70%;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
}

.Your_Inquiries .Your_Inquiries_holder .Your_Inquiries_form_holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 9px;
  padding: 25px 50px;
  transition: all 0.3s ease-in-out;
}

.Your_Inquiries
  .Your_Inquiries_holder
  .Your_Inquiries_form_holder
  .form_holder {
  height: 250px;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.Your_Inquiries
  .Your_Inquiries_holder
  .Your_Inquiries_form_holder
  .form_holder::-webkit-scrollbar {
  width: 0px;
}

@media (min-width: 0px) and (max-width: 280px) {
  .Your_Inquiries .Your_Inquiries_holder .main-form-holder {
    position: unset;
    right: 0px;
    width: unset;
    margin-top: 15px;
    padding: 0;
  }

  .Your_Inquiries .Your_Inquiries_holder .Your_Inquiries_form_holder {
    padding: 25px 10px;
  }
}

@media (min-width: 281px) and (max-width: 320px) {
  .Your_Inquiries .Your_Inquiries_holder .main-form-holder {
    position: unset;
    right: 0px;
    width: unset;
    margin-top: 15px;
    padding: 0;
  }

  .Your_Inquiries .Your_Inquiries_holder .Your_Inquiries_form_holder {
    padding: 25px 10px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Your_Inquiries .Your_Inquiries_holder .main-form-holder {
    position: unset;
    right: 0px;
    width: unset;
    margin-top: 15px;
    padding: 0;
  }

  .Your_Inquiries .Your_Inquiries_holder .Your_Inquiries_form_holder {
    padding: 25px 10px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Your_Inquiries .Your_Inquiries_holder .main-form-holder {
    position: unset;
    right: 0px;
    width: unset;
    margin-top: 15px;
    padding: 0;
  }
  .Your_Inquiries .Your_Inquiries_holder .Your_Inquiries_form_holder {
    padding: 25px 10px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Your_Inquiries
    .Your_Inquiries_holder
    .Your_Inquiries_form_holder
    .form_holder {
    height: 100px;
  }

  .Your_Inquiries .Your_Inquiries_holder .Your_Inquiries_form_holder {
    padding: 20px 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Your_Inquiries
    .Your_Inquiries_holder
    .Your_Inquiries_form_holder
    .form_holder {
    height: 100px;
  }

  .Your_Inquiries .Your_Inquiries_holder .Your_Inquiries_form_holder {
    padding: 20px 15px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Your_Inquiries
    .Your_Inquiries_holder
    .Your_Inquiries_form_holder
    .form_holder {
    height: 200px;
  }

  .Your_Inquiries .Your_Inquiries_holder .Your_Inquiries_form_holder {
    padding: 20px 15px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Your_Inquiries
    .Your_Inquiries_holder
    .Your_Inquiries_form_holder
    .form_holder {
    height: 180px;
  }

  .Your_Inquiries .Your_Inquiries_holder .Your_Inquiries_form_holder {
    padding: 10px 50px;
}
}
