.footer-main-sec {
    background-color: #000;
    padding-bottom: 20px;
    overflow: hidden;
}

.footer-main-sec .foottitlediv .title {
    font-weight: 900;
    font-size: 75px;
    text-align: center;
    color: #292929;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.footer-main-sec .foottitlediv {
    margin-bottom: 30px;
    margin-top: -20px;
    display: none;
}

.footer-main-sec .menus-main-div {
    text-align: center;
    width: fit-content;
    border-bottom: 1px solid #fff;
    margin: 0px auto 65px auto;
}

.footer-main-sec .menus-main-div .flex-menus {
    display: ruby;
    justify-content: center;
    /* border-bottom: 1px solid #fff; */
    /* padding-bottom: 35px; */
}

.footer-main-sec .menus-main-div .flex-menus .menu {
    color: #fff;
    text-decoration: none;
}

.footer-main-sec .menus-main-div .flex-menus .menudiv {
    margin: 30px 20px;
}

.footer-main-sec .addd-sec .adddiv .addtitle {
    font-size: 14px;
    color: #fff;
    margin-bottom: 0px;
}

.footer-main-sec .addd-sec .adddiv .addtitle span {
    font-size: 14px;
    color: #000;
    background-color: #fff;
    margin-bottom: 0px;
    padding: 0px 5px;
    margin-right: 5px;
}

.footer-main-sec .copyrightsdiv {
    text-align: center;
    margin-top: 30px;
}

.footer-main-sec .copyrightsdiv p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 0px;
}


.footer-main-sec .copyrightsdiv a{
    color: #fff;
    font-size: 15px;
    margin-bottom: 0px;
    text-decoration: none;
}


@media (min-width:0px) and (max-width:767.98px) {
    .footer-main-sec .foottitlediv .title {
        font-size: 30px;
    }

    .footer-main-sec .menus-main-div .flex-menus {
        display: unset;
        justify-content: start;
    }

    .footer-main-sec .menus-main-div .flex-menus .menudiv {
        margin: 15px 0px;
    }

    .footer-main-sec .menus-main-div {
        text-align: left;
        width: auto;
        border-bottom: 0px solid #fff;
        margin: 30px auto;
    }

    .footer-main-sec .menus-main-div .flex-menus .menu {
        font-size: 14px;
    }

    .footer-main-sec .addd-sec .adddiv .addtitle span {
        font-size: 14px;
    }

    .footer-main-sec .addd-sec .adddiv .addtitle {
        font-size: 14px;
    }

    .footer-main-sec .copyrightsdiv p {
        font-size: 12px;
    }

    .footer-main-sec .foottitlediv {
        margin-top: -7px;
    }
}


@media (min-width:768px) and (max-width:1200px) {
    .footer-main-sec .foottitlediv .title {
        font-size: 60px;
    }

    .footer-main-sec .menus-main-div {
        margin: 30px auto;
    }

    .footer-main-sec .menus-main-div .flex-menus .menudiv {
        margin: 20px 10px;
    }

    .footer-main-sec .menus-main-div .flex-menus .menu {
        font-size: 14px;
    }

    .footer-main-sec .addd-sec .adddiv .addtitle {
        font-size: 14px;
    }

    .footer-main-sec .copyrightsdiv p {
        font-size: 13px;
    }
}

@media (min-width:1201px) and (max-width:1400px) {}