.common-section {
  margin-top: -100px;
  margin-bottom: 5%;
}

.Scaffolding_on_Rent_content_holder .tabs-container_holder {
  display: flex;
  justify-content: center;
  background: #aaaaaa 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: fit-content;
  margin: 0px auto;
  padding: 7px 7px;
}

.Scaffolding_on_Rent_content_holder .outer-tab-content .inner-heading-text {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-transform: inherit;
}

.Scaffolding_on_Rent_content_holder .tabs-container_holder .nav-link {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
}

.Scaffolding_on_Rent_content_holder .tabs-container_holder .nav-link.active {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #000;
  font-weight: 600;
}

.Scaffolding_on_Rent_content_holder .outer-tab-content {
  margin-top: 50px;
}

.Scaffolding_on_Rent_content_holder .inner-tab-content {
  margin-top: 30px;
}

.common-section .tab-content-holder h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #8e1c79;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.common-section .tab-content-holder p {
  text-align: justify;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.Scaffolding_on_Rent_content_holder
  .inner-tabs-holder
  .inner-tab-content-holder
  .inner-tab-heading
  h3 {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
}

.Scaffolding_on_Rent_content_holder
  .inner-tabs-holder
  .inner-tabs-container_holder
  .nav-link {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  padding: 5px 30px;
  border-radius: 0px;
}

.Scaffolding_on_Rent_content_holder
  .inner-tabs-holder
  .inner-tabs-container_holder
  .nav-link.active {
  background: #aaaaaa 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 4px 4px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 5px 30px;
}

.Scaffolding_on_Rent_content_holder
  .inner-tabs-holder
  .inner-tabs-container_holder
  .nav-link:focus-visible {
  box-shadow: none;
  padding: 5px 15px;
}

.Scaffolding_on_Rent_content_holder
  .inner-tabs-holder
  .inner-tabs-container_holder {
  border-top: 1px solid #aaaaaa;
}

.Scaffolding_on_Rent_content_holder .inner-tabs-holder .table-data-holder {
  border: 1px solid #f2f1f1;
}

.Scaffolding_on_Rent_content_holder .inner-tabs-holder .table-data-holder th {
  background-color: #f2f1f1;
  font-size: 14px;
  font-weight: 600;
}

.Scaffolding_on_Rent_content_holder .inner-tabs-holder .table-data-holder td {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.Scaffolding_on_Rent_content_holder
  .inner-tabs-holder
  .table-data-holder
  .record-table-holder {
  margin-bottom: 0px;
}

.Scaffolding_on_Rent_content_holder
  .inner-tabs-holder
  .table-data-holder
  .table
  > :not(caption)
  > *
  > * {
  border-right-width: var(--bs-border-width);
  border-bottom: none;
}

.Scaffolding_on_Rent_content_holder .main-table-holder {
  margin-bottom: 50px;
}

.Scaffolding_on_Rent_content_holder .main-table-holder .description-holder p {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.Scaffolding_on_Rent_content_holder .inner-tabs-holder .table-heading-holder {
  background: #fbd8f4 0% 0% no-repeat padding-box;
  padding: 7px 0px;
  margin-bottom: 1px;
}

.Scaffolding_on_Rent_content_holder
  .inner-tabs-holder
  .table-heading-holder
  h3 {
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0px;
}

.Scaffolding_on_Rent_content_holder
  .inner-tabs-holder
  .Advantages-list-holder
  li {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  list-style: square;
  margin-top: 10px;
}

.Scaffolding_on_Rent_content_holder
  .inner-tabs-holder
  .Advantages-list-holder
  li::marker {
  border: 1px solid #8e1c79;
  color: #fad8f4;
  font-size: 18px;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Scaffolding_on_Rent_content_holder .tab-content-holder {
    margin-top: 50px;
  }

  .Scaffolding_on_Rent_content_holder
    .inner-tabs-holder
    .inner-tabs-container_holder {
    flex-wrap: nowrap;
    overflow: scroll;
  }

  .Scaffolding_on_Rent_content_holder
    .inner-tabs-holder
    .inner-tabs-container_holder::-webkit-scrollbar {
    height: 0px;
  }

  .Scaffolding_on_Rent_content_holder
    .inner-tabs-holder
    .inner-tabs-container_holder
    .nav-link {
    text-wrap: nowrap;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Scaffolding_on_Rent_content_holder .tab-content-holder {
    margin-top: 50px;
  }

  .Scaffolding_on_Rent_content_holder
    .inner-tabs-holder
    .inner-tabs-container_holder {
    flex-wrap: nowrap;
    overflow: scroll;
  }

  .Scaffolding_on_Rent_content_holder
    .inner-tabs-holder
    .inner-tabs-container_holder::-webkit-scrollbar {
    height: 0px;
  }

  .Scaffolding_on_Rent_content_holder
    .inner-tabs-holder
    .inner-tabs-container_holder
    .nav-link {
    text-wrap: nowrap;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Scaffolding_on_Rent_content_holder .tab-content-holder {
    margin-top: 50px;
  }

  .Scaffolding_on_Rent_content_holder
    .inner-tabs-holder
    .inner-tabs-container_holder {
    flex-wrap: nowrap;
    overflow: scroll;
  }

  .Scaffolding_on_Rent_content_holder
    .inner-tabs-holder
    .inner-tabs-container_holder::-webkit-scrollbar {
    height: 0px;
  }

  .Scaffolding_on_Rent_content_holder
    .inner-tabs-holder
    .inner-tabs-container_holder
    .nav-link {
    text-wrap: nowrap;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Scaffolding_on_Rent_content_holder .tab-content-holder {
    margin-top: 50px;
  }
}
