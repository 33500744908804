.About_content_sec {
  position: relative;
}
.About_content_sec .backgrounddiv {
  background-color: #fff;
  padding: 25px 20px;
  border-radius: 8px;
  box-shadow: 0px -7px 10px 0px #51515133;
  margin: -300px 0px 20px 0px;
  /* position: absolute; */
  width: 100%;
}

.About_content_sec .backgrounddiv .heading-holder-section {
  text-align: center;
}

.About_content_sec .backgrounddiv .titlemain-div {
  text-align: center;
}
.About_content_sec .backgrounddiv .titlemain-div .bigtitle {
  font-family: Raleway;
  font-weight: bold;
  font-size: 80px;
  color: #f3e8f1;
  margin-bottom: 0px;
}
.About_content_sec .backgrounddiv .abt-contdiv {
  margin: 40px 0px;
}

.About_content_sec .backgrounddiv .main-section {
  margin-bottom: 0px;
}
.About_content_sec .backgrounddiv .abt-contdiv .sec-1 {
  padding: 50px 0px;
}
.About_content_sec .backgrounddiv .abt-contdiv .sec-1 .content {
  margin-bottom: 0px;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}

.About_content_sec .backgrounddiv .abt-contdiv .sec-2 .img-div {
  overflow: hidden;
  box-sizing: content-box;
}
.About_content_sec .backgrounddiv .abt-contdiv .sec-2 .img-div .immage {
  width: 100%;
  height: auto;
  transform: scale(1.2);
  transition: 0.3s;
  z-index: 1;
  position: relative;
}
.About_content_sec .backgrounddiv .abt-contdiv .sec-2 .img-div:hover .immage {
  width: 100%;
  height: auto;
  transform: scale(1);
  transition: 0.3s;
  object-position: left;
}
.About_content_sec .backgrounddiv .abt-contdiv .sec-2 {
  position: relative;
  padding: 50px 0px;
}
.About_content_sec .backgrounddiv .abt-contdiv .sec-2 .layerbg {
  height: 100%;
  width: 65px;
  background: #8e1c79;
  position: absolute;
  top: 0px;
  right: -20px;
  border-radius: 50px 0px 0px 0px;
  transition: 0.4s;
}
.About_content_sec .backgrounddiv .abt-contdiv .sec-2:hover .layerbg {
  height: 100%;
  width: 300px;
  background: #8e1c79;
  position: absolute;
  top: 0px;
}

.About_content_sec .main-about-us-sec {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

@media (min-width: 0px) and (max-width: 575px) {
  .About_content_sec .backgrounddiv {
    margin: -50px 0px 20px 0px;
  }
  .About_content_sec .backgrounddiv .abt-contdiv .sec-1 {
    padding: 10px 0px;
  }
  .About_content_sec .backgrounddiv .abt-contdiv .sec-1 .content {
    font-size: 13px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .About_content_sec .backgrounddiv {
    margin: -150px 0px 20px 0px;
  }
  .About_content_sec .backgrounddiv .abt-contdiv .sec-1 {
    padding: 10px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .About_content_sec .backgrounddiv {
    margin: -40px 0px 20px 0px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .About_content_sec .backgrounddiv .abt-contdiv .sec-1 .content {
    font-size: 14px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .About_content_sec .backgrounddiv .abt-contdiv .sec-1 .content {
    font-size: 14px;
  }
  .About_content_sec .backgrounddiv {
    margin: -43px 0 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .About_content_sec .backgrounddiv .abt-contdiv .sec-1 .content {
    font-size: 14px;
  }
  .About_content_sec .backgrounddiv {
    margin: -121px 0 20px;
  }
}

@media (min-width: 1401px) and (max-width: 1500px) {
  .About_content_sec .backgrounddiv {
    margin: -150px 0px 20px 0px;
  }
}
