.Walking_With_Giants .Walking_With_Giants_holder .heading_holder {
  margin-bottom: 5%;
}

.Walking_With_Giants .Walking_With_Giants_holder .heading_holder .heading-text {
  text-align: center;
}

.Walking_With_Giants
  .Walking_With_Giants_holder
  .Walking_With_Giants_slider_holder
  .Walking_With_Giants_slider {
  padding-bottom: 40px;
}

.Walking_With_Giants .Walking_With_Giants_holder .Walking_With_Giants_slider_holder .Walking_With_Giants_slider .Walking_With_Giants_card .img-holder .Walking_With_Giants_img {
    height: 100%;
    width: 100%;
    aspect-ratio: 1/0.8;
    object-fit: contain;
}
