.Services_Banner .service-heading-holder {
  position: absolute;
  left: 0;
  right: 0;
  top: 200px;
}

.Services_Banner {
  z-index: -1;
}

.Services_Banner .bannerimg {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Services_Banner .service-heading-holder {
    top: 100px;
  }

  .Services_Banner .bannerimg {
    height: 280px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Services_Banner .service-heading-holder {
    top: 100px;
  }

  .Services_Banner .bannerimg {
    height: 280px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Services_Banner .service-heading-holder {
    top: 100px;
}

  .Services_Banner .bannerimg {
    height: 280px;
  }

  .common-section {
    margin-top: -129px;
}
}

@media (min-width: 576px) and (max-width: 767px) {
  .Services_Banner .service-heading-holder {
    top: 129px;
}

  .Services_Banner .bannerimg {
    height: 280px;
  }

  .common-section {
    margin-top: -77px;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .Services_Banner .service-heading-holder {
    top: 150px;
  }

  .Services_Banner .bannerimg {
    height: 350px;
}
}


