.common-btn-holder .common_btn {
  background: #ffffff;
  border: 1px solid #000;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #8e1c79;
  width: 100%;
  padding: 25px 0px;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}



.common-btn-holder .common_btn:hover p {
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.common-btn-holder p {
  margin: 0px;
  position: absolute;
  z-index: 1;
}

.common-btn-holder .common_btn::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: #000;
  width: 100%;
  height: 0%;
  transition: all 0.3s ease-in-out;
}

.common-btn-holder .common_btn::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background: #000;
  width: 100%;
  height: 0%;
  transition: all 0.3s ease-in-out;
}

.common-btn-holder .common_btn:hover::before {
  height: 50%;
  transition: all 0.3s ease-in-out;
}

.common-btn-holder .common_btn:hover::after {
  height: 50%;
  transition: all 0.3s ease-in-out;
}

.common-btn-holder .common_btn:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #8e1c79;
}

.common-btn-holder .common_btn:first-child:active {
  background: #fff;
  border: 1px solid #fff;
  color: #8e1c79;
}

.common-btn-holder .contact-btn {
  background: #ffffff;
  border: 1px solid #000;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #8e1c79;
  width: 100%;
  padding: 25px 0px;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}


.common-btn-holder .contact-btn:hover p {
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.common-btn-holder p {
  margin: 0px;
  position: absolute;
  z-index: 1;
}

.common-btn-holder .contact-btn::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: #000;
  width: 100%;
  height: 0%;
  transition: all 0.3s ease-in-out;
}

.common-btn-holder .contact-btn::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background: #000;
  width: 100%;
  height: 0%;
  transition: all 0.3s ease-in-out;
}

.common-btn-holder .contact-btn:hover::before {
  height: 50%;
  transition: all 0.3s ease-in-out;
}

.common-btn-holder .contact-btn:hover::after {
  height: 50%;
  transition: all 0.3s ease-in-out;
}

.common-btn-holder .contact-btn:hover {
  background: #fff;
  border: 1px solid #000;
  color: #8e1c79;
}

.common-btn-holder .contact-btn:first-child:active {
  background: #fff;
  border: 1px solid #000;
  color: #8e1c79;
}


@media (min-width: 0px) and (max-width: 280px) {
  .common-btn-holder .common_btn {
    font-size: 13px;
    padding: 5px 0px;
  }
}

@media (min-width: 281px) and (max-width: 320px) {
  .common-btn-holder .common_btn {
    font-size: 13px;
    padding: 18px 0px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .common-btn-holder .common_btn {
    font-size: 13px;
    padding: 18px 0px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .common-btn-holder .common_btn {
    font-size: 13px;
    padding: 18px 0px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .common-btn-holder .common_btn {
    font-size: 13px;
    padding: 18px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .common-btn-holder .common_btn {
    font-size: 13px;
    padding: 18px 0px;
  }
}
