.Our_Success {
  margin-top: 50px;
  padding: 50px 0px;
}

.Our_Success .heading-content-holder .subheading_holder {
  margin-bottom: 30px;
}

.Our_Success .Our_Success_holder .right-border-holder {
  position: relative;
}

.Our_Success .Our_Success_holder .right-border-holder::after {
  content: "";
  position: absolute;
  border-right: 2px solid #8e1c79;
  height: 40px;
  right: 0px;
  top: 0;
  transition: all 0.3s ease-in-out;
}

.Our_Success .Our_Success_holder .counter-holder-main {
  padding-left: 13px;
}

.Our_Success .Our_Success_holder .right-border-holder:hover::after {
  content: "";
  position: absolute;
  border-right: 2px solid #000;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.Our_Success .Our_Success_holder .counter-holder .counter-heading-holder {
  margin-bottom: 30px;
}

.Our_Success .Our_Success_holder .counter-holder .counter-heading-holder h4 {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
  text-align: left;
  font-size: 32px;
  font-weight: 600;
  color: #000000;
}

.Our_Success .Our_Success_holder .counter-holder .counter-holder p {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  overflow-wrap: break-word;
}

.Our_Success .Our_Success_holder .right-border-holder:hover .counter-holder h3 {
  color: #8e1c79;
}

.Our_Success .Our_Success_holder .right-border-holder:hover .counter-holder p {
  color: #8e1c79;
}

.Our_Success .Our_Success_holder .no-right-border::after {
  display: none;
}

@media (min-width: 0px) and (max-width: 280px) {
  .Our_Success .Our_Success_holder .counter-holder-main {
    padding-left: 0px;
  }
  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 25px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder {
    margin-bottom: 20px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-holder p {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder h4 {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 20px;
  }

  .Our_Success .heading-content-holder .subheading_holder {
    margin-bottom: 10px;
  }

  .Our_Success .heading-content-holder {
    text-align: center;
    margin-bottom: 30px;
  }

  .Our_Success .Our_Success_holder .right-border-holder::after {
    display: none;
  }

  .Our_Success {
    margin-top: 0px;
    padding-bottom: 0px;
  }

  .Our_Success .Our_Success_holder .margin-bottom-holder {
    margin-bottom: 20px;
  }
}

@media (min-width: 281px) and (max-width: 320px) {
  .Our_Success .Our_Success_holder .counter-holder-main {
    padding-left: 0px;
  }
  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 25px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-holder p {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder h4 {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 20px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder {
    margin-bottom: 20px;
  }

  .Our_Success .heading-content-holder .subheading_holder {
    margin-bottom: 10px;
  }

  .Our_Success .heading-content-holder {
    text-align: center;
    margin-bottom: 30px;
  }

  .Our_Success .Our_Success_holder .right-border-holder::after {
    display: none;
  }

  .Our_Success {
    margin-top: 0px;
    padding-bottom: 0px;
  }

  .Our_Success .Our_Success_holder .margin-bottom-holder {
    margin-bottom: 20px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Our_Success .Our_Success_holder .counter-holder-main {
    padding-left: 0px;
  }
  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 25px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder {
    margin-bottom: 20px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-holder p {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder h4 {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 20px;
  }

  .Our_Success .heading-content-holder .subheading_holder {
    margin-bottom: 10px;
  }

  .Our_Success .heading-content-holder {
    text-align: center;
    margin-bottom: 30px;
  }

  .Our_Success .Our_Success_holder .right-border-holder::after {
    display: none;
  }

  .Our_Success {
    margin-top: 0px;
    padding-bottom: 0px;
  }

  .Our_Success .Our_Success_holder .margin-bottom-holder {
    margin-bottom: 20px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Our_Success .Our_Success_holder .counter-holder-main {
    padding-left: 0px;
  }
  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 25px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder {
    margin-bottom: 20px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 20px;
  }

  .Our_Success .heading-content-holder .subheading_holder {
    margin-bottom: 10px;
  }

  .Our_Success .heading-content-holder {
    text-align: center;
    margin-bottom: 30px;
  }

  .Our_Success .Our_Success_holder .right-border-holder::after {
    display: none;
  }

  .Our_Success {
    margin-top: 0px;
    padding-bottom: 0px;
  }

  .Our_Success .Our_Success_holder .margin-bottom-holder {
    margin-bottom: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Our_Success .Our_Success_holder .counter-holder-main {
    padding-left: 0px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 25px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder {
    margin-bottom: 20px;
  }

  .Our_Success .heading-content-holder .subheading_holder {
    margin-bottom: 10px;
  }

  .Our_Success .heading-content-holder {
    text-align: center;
    margin-bottom: 30px;
  }

  .Our_Success .Our_Success_holder .right-border-holder::after {
    display: none;
  }

  .Our_Success {
    margin-top: 0px;
  }

  .Our_Success .Our_Success_holder .margin-bottom-holder {
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Our_Success .Our_Success_holder .counter-holder .counter-holder p {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder h4 {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 21px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder {
    margin-bottom: 20px;
  }

  .Our_Success .heading-content-holder .subheading_holder {
    margin-bottom: 10px;
  }

  .Our_Success .heading-content-holder {
    text-align: center;
    margin-bottom: 30px;
  }

  .Our_Success .Our_Success_holder .right-border-holder::after {
    height: 20px;
  }

  .Our_Success {
    margin-top: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Our_Success .Our_Success_holder .counter-holder .counter-holder p {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder h4 {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 21px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder {
    margin-bottom: 20px;
  }

  .Our_Success .heading-content-holder .subheading_holder {
    margin-bottom: 20px;
  }

  .Our_Success .Our_Success_holder .right-border-holder::after {
    height: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Our_Success .Our_Success_holder .counter-holder .counter-holder p {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder h4 {
    font-size: 14px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-holder h3 {
    font-size: 21px;
  }

  .Our_Success .Our_Success_holder .counter-holder .counter-heading-holder {
    margin-bottom: 20px;
  }

  .Our_Success .heading-content-holder .subheading_holder {
    margin-bottom: 20px;
  }

  .Our_Success .Our_Success_holder .right-border-holder::after {
    height: 20px;
  }
}
