.Our_Services {
  margin-bottom: 50px;
}

.Our_Services .Our_Services_holder .heading-holder-section {
  text-align: center;
}

.Our_Services .Our_Services_holder .Our_Services_card_slider {
  margin-top: 5%;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_card {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_card
  .img-holder
  .Our_Services_img {
    width: 100%;
    height: 100%;
    border: 2px solid #707070;
    border-radius: 5px;
    aspect-ratio: 1/1.2;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_card
  .overlay-holder {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 25%;
  background: transparent linear-gradient(0deg, #000000ba 0%, #54545400 100%) 0%
    0% no-repeat padding-box;
  transition: all 0.2s ease-in-out;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_card:hover
  .overlay-holder {
  transition: all 0.2s ease-in-out;
  height: 100%;
  background: transparent
    linear-gradient(
      0deg,
      #000000a8 0%,
      #00000070 16%,
      #0000008f 75%,
      #000000fc 100%
    )
    0% 0% no-repeat padding-box;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_card
  .text-btn-holder {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
  transition: all 0.2s ease-in-out;
  padding: 0px 2px;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_card:hover
  .text-btn-holder {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
  transform: translateY(-100%);
  transition: all 0.2s ease-in-out;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_card
  .text-holder
  p {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_card
  .view-btn-holder {
  display: none;
  text-align: center;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_card:hover
  .view-btn-holder {
  display: block;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_card
  .view-btn-holder
  .view-btn {
  background: #000000 0% 0% no-repeat padding-box;
  border: 1px solid #000;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_card
  .view-btn-holder
  .view-btn:hover {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #fff;
  text-decoration: underline;
  color: #000;
  transition: all 0.2s ease-in-out;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_slider {
  padding-bottom: 80px;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_slider
  .swiper-button-prev {
  top: unset;
  bottom: 0px;
  left: 49%;
}

.Our_Services
  .Our_Services_holder
  .Our_Services_card_slider
  .Our_Services_slider
  .swiper-button-next {
  top: unset;
  bottom: 0px;
  left: 53%;
}

@media (min-width: 0px) and (max-width: 280px) {
  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card:hover
    .text-btn-holder {
    transform: translateY(-60%);
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .text-holder
    p {
    font-size: 10px;
    margin-bottom: 5px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .view-btn-holder
    .view-btn {
    font-size: 10px;
    padding: 3px 7px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-next {
    width: 25px;
    height: 25px;
    left: 55%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-prev:after {
    font-size: 10px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-prev {
    width: 25px;
    height: 25px;
    left: 40%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider {
    padding-bottom: 45px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-next:after {
    font-size: 10px;
  }
}

@media (min-width: 281px) and (max-width: 320px) {
  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card:hover
    .text-btn-holder {
    transform: translateY(-60%);
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .text-holder
    p {
    font-size: 10px;
    margin-bottom: 5px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .view-btn-holder
    .view-btn {
    font-size: 10px;
    padding: 3px 7px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-next {
    left: 55%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-prev {
    left: 40%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider {
    padding-bottom: 45px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card:hover
    .text-btn-holder {
    transform: translateY(-60%);
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .text-holder
    p {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .view-btn-holder
    .view-btn {
    font-size: 10px;
    padding: 3px 7px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-next {
    left: 55%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-prev {
    left: 45%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider {
    padding-bottom: 45px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card:hover
    .text-btn-holder {
    transform: translateY(-60%);
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .text-holder
    p {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .view-btn-holder
    .view-btn {
    font-size: 13px;
    padding: 3px 7px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-next {
    left: 55%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-prev {
    left: 45%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider {
    padding-bottom: 45px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card:hover
    .text-btn-holder {
    transform: translateY(-60%);
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .text-holder
    p {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .view-btn-holder
    .view-btn {
    font-size: 12px;
    padding: 3px 7px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-next {
    left: 55%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-prev {
    left: 45%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider {
    padding-bottom: 45px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card:hover
    .text-btn-holder {
    transform: translateY(-60%);
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .text-holder
    p {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .view-btn-holder
    .view-btn {
    font-size: 12px;
    padding: 3px 7px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-next {
    left: 53%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-prev {
    left: 47%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider {
    padding-bottom: 45px;
  }
}

@media (min-width: 991px) and (max-width: 1023px) {
  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card:hover
    .text-btn-holder {
    transform: translateY(-200%);
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .text-holder
    p {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .view-btn-holder
    .view-btn {
    font-size: 12px;
    padding: 3px 7px;
  }
  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .view-btn-holder
    .view-btn {
    font-size: 12px;
    padding: 3px 7px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-next {
    left: 53%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-prev {
    left: 49%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider {
    padding-bottom: 45px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .text-holder
    p {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_card
    .view-btn-holder
    .view-btn {
    font-size: 12px;
    padding: 3px 7px;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-next {
    left: 53%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider
    .swiper-button-prev {
    left: 49%;
  }

  .Our_Services
    .Our_Services_holder
    .Our_Services_card_slider
    .Our_Services_slider {
    padding-bottom: 45px;
  }
}
