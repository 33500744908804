@font-face {
  font-family: Roboto !important;
  src: url('/public/assets/Images/Roboto-Black.ttf');
}

body {
  font-family: Roboto !important;
}

.heading-text {
  font-size: 28px;
  font-weight: 600;
  color: #000000;
}

.subheading {
  font-size: 18px;
  font-weight: 600;
  color: #8e1c79;
}

.heading-holder-section {
  /* position: relative; */
}

.heading-holder-section .main-heading {
  font-size: 80px;
  font-weight: 800;
  color: #8e1c79;
  opacity: 0.18;
  display: none;
}

.heading-holder-section .heading {
  font-size: 28px;
  font-weight: 600;
  color: #000000;
  /* position: absolute; */
  /* bottom: -7px; */
  left: 0px;
  right: 0px;
}

.myswiper .swiper-button-prev:after {
  font-size: 16px;
  color: #000;
  font-weight: 900;
}

.myswiper .swiper-button-next:after {
  font-size: 16px;
  color: #000;
  font-weight: 900;
}

.myswiper .swiper-button-prev {
  width: 40px;
  height: 40px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 50%;
}

.myswiper .swiper-button-next {
  width: 40px;
  height: 40px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 50%;
}

.myswiper .swiper-button-prev:hover {
  background: #8e1c79 0% 0% no-repeat padding-box;
}

.myswiper .swiper-button-next:hover {
  background: #8e1c79 0% 0% no-repeat padding-box;
}

.myswiper .swiper-button-prev:hover::after {
  color: #fff;
}

.myswiper .swiper-button-next:hover::after {
  color: #fff;
}

.myswiper .swiper-pagination-bullet {
  width: 16px;
  height: 5px;
  background: #707070 0% 0% no-repeat padding-box;
  border-radius: 22px;
  opacity: 1;
}

.myswiper .swiper-pagination-bullet-active {
  background: #8e1c79 0% 0% no-repeat padding-box;
  border-radius: 22px;
  width: 31px;
  height: 5px;
}

.form-section .form-label {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.form-section .form-control {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  height: 48px;
  color: #000;
  font-size: 15px;
}

.form-section .form-control:focus {
  color: #000;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #f5f5f5;
  outline: 0;
  box-shadow: none;
}

.form-section .textarea-holder {
  height: unset;
}

.form-section .Form-field-holder {
  margin-bottom: 15px;
}

@media (min-width: 0px) and (max-width: 280px) {
  .Component-holder .Component_Header-holder {
    top: 10px;
  }

  .heading-text {
    font-size: 18px;
  }

  .heading-holder-section .main-heading {
    font-size: 35px;
  }

  .heading-holder-section .heading {
    font-size: 18px;
    bottom: -12px;
  }

  .myswiper .swiper-button-next {
    width: 25px;
    height: 25px;
  }

  .myswiper .swiper-button-prev:after {
    font-size: 10px;
  }

  .myswiper .swiper-button-prev {
    width: 25px;
    height: 25px;
  }

  .myswiper .swiper-button-next:after {
    font-size: 10px;
  }

  .form-section .form-control {
    height: unset;
    font-size: 13px;
  }

  .form-section .form-label {
    font-size: 12px;
  }

  .subheading {
    font-size: 15px;
  }
}

@media (min-width: 281px) and (max-width: 320px) {
  .Component-holder .Component_Header-holder {
    top: 10px;
  }
  .heading-text {
    font-size: 18px;
  }

  .subheading {
    font-size: 15px;
  }

  .heading-holder-section .main-heading {
    font-size: 30px;
  }

  .heading-holder-section .heading {
    font-size: 18px;
    bottom: -12px;
  }

  .myswiper .swiper-button-next {
    width: 25px;
    height: 25px;
  }

  .myswiper .swiper-button-prev:after {
    font-size: 10px;
  }

  .myswiper .swiper-button-prev {
    width: 25px;
    height: 25px;
  }

  .myswiper .swiper-button-next:after {
    font-size: 10px;
  }

  .form-section .form-control {
    height: unset;
    font-size: 13px;
  }

  .form-section .form-label {
    font-size: 12px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Component-holder .Component_Header-holder {
    top: 10px;
  }
  .heading-text {
    font-size: 18px;
  }

  .subheading {
    font-size: 15px;
  }

  .heading-holder-section .main-heading {
    font-size: 35px;
  }

  .heading-holder-section .heading {
    font-size: 18px;
    bottom: -12px;
  }

  .myswiper .swiper-button-next {
    width: 25px;
    height: 25px;
  }

  .myswiper .swiper-button-prev:after {
    font-size: 10px;
  }

  .myswiper .swiper-button-prev {
    width: 25px;
    height: 25px;
  }

  .myswiper .swiper-button-next:after {
    font-size: 10px;
  }

  .form-section .form-control {
    height: unset;
    font-size: 13px;
  }

  .form-section .form-label {
    font-size: 12px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .heading-text {
    font-size: 20px;
  }

  .heading-holder-section .main-heading {
    font-size: 30px;
  }

  .subheading {
    font-size: 15px;
  }

  .heading-holder-section .heading {
    font-size: 20px;
    bottom: -10px;
  }

  .myswiper .swiper-button-next {
    width: 25px;
    height: 25px;
  }

  .myswiper .swiper-button-prev:after {
    font-size: 10px;
  }

  .myswiper .swiper-button-prev {
    width: 25px;
    height: 25px;
  }

  .myswiper .swiper-button-next:after {
    font-size: 10px;
  }

  .form-section .form-control {
    height: unset;
    font-size: 13px;
  }

  .form-section .form-label {
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .heading-text {
    font-size: 18px;
  }
  .heading-holder-section .main-heading {
    font-size: 39px;
  }

  .heading-holder-section .heading {
    font-size: 20px;
    bottom: -10px;
  }

  .myswiper .swiper-button-next {
    width: 30px;
    height: 30px;
  }

  .myswiper .swiper-button-prev:after {
    font-size: 10px;
  }

  .myswiper .swiper-button-prev {
    width: 30px;
    height: 30px;
  }

  .myswiper .swiper-button-next:after {
    font-size: 10px;
  }

  .form-section .form-control {
    height: unset;
    font-size: 13px;
  }

  .form-section .form-label {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .heading-text {
    font-size: 20px;
  }

  .heading-holder-section .main-heading {
    font-size: 50px;
  }

  .heading-holder-section .heading {
    font-size: 25px;
    bottom: -10px;
  }

  .myswiper .swiper-button-next {
    width: 30px;
    height: 30px;
  }

  .myswiper .swiper-button-prev:after {
    font-size: 10px;
  }

  .myswiper .swiper-button-prev {
    width: 30px;
    height: 30px;
  }

  .myswiper .swiper-button-next:after {
    font-size: 10px;
  }

  .form-section .form-control {
    height: unset;
    font-size: 13px;
  }

  .form-section .form-label {
    font-size: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .heading-text {
    font-size: 25px;
  }

  .heading-holder-section .main-heading {
    font-size: 50px;
  }

  .heading-holder-section .heading {
    font-size: 25px;
    bottom: -10px;
  }

  .myswiper .swiper-button-next {
    width: 30px;
    height: 30px;
  }

  .myswiper .swiper-button-prev:after {
    font-size: 10px;
  }

  .myswiper .swiper-button-prev {
    width: 30px;
    height: 30px;
  }

  .myswiper .swiper-button-next:after {
    font-size: 10px;
  }

  .form-section .form-control {
    height: unset;
    font-size: 13px;
  }

  .form-section .form-label {
    font-size: 12px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .heading-text {
    font-size: 22px;
  }

  .heading-holder-section .main-heading {
    font-size: 60px;
  }

  .heading-holder-section .heading {
    font-size: 25px;
    bottom: -10px;
  }

  .myswiper .swiper-button-next {
    width: 30px;
    height: 30px;
  }

  .myswiper .swiper-button-prev:after {
    font-size: 10px;
  }

  .myswiper .swiper-button-prev {
    width: 30px;
    height: 30px;
  }

  .myswiper .swiper-button-next:after {
    font-size: 10px;
  }

  .form-section .form-control {
    height: unset;
  }
}
