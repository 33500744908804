.About_team_sec {
  padding-bottom: 5%;
}
.About_team_sec .teamdiv {
  margin-top: 70px;
}
.About_team_sec .teamdiv .teamcard {
  border-radius: 8px;
  background-color: #f5f5f5;
}
.About_team_sec .teamdiv .teamcard:hover {
  border: 1px solid #8e1c79;
}

.About_team_sec .teamdiv .teamcard .main-img-holder {
  padding: 10px 10px 0px 10px;
}

.About_team_sec .teamdiv .teamcard .img-div {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-radius: 8px 8px 0px 0px;
  box-sizing: content-box;
  overflow: hidden;
  transition: 0.3s;
}

.About_team_sec .teamdiv .teamcard .detailsdiv .name {
  font-weight: 600;
  font-size: 16px;
  color: #000;
}

.About_team_sec .teamdiv .teamcard .detailsdiv .position {
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  margin-bottom: 0px;
}

.About_team_sec .teamdiv .teamcard:hover .detailsdiv .position {
  color: #fff;
}

.About_team_sec .teamdiv .teamcard:hover .detailsdiv .name {
  color: #fff;
}

.About_team_sec .teamdiv .teamcard:hover .detailsdiv {
  background: linear-gradient(180deg, #8e1c79, #470c3c);
  border-radius: 0px 0px 8px 8px;
}

.About_team_sec .teamdiv .teamcard .detailsdiv {
  padding: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.About_team_sec .teamdiv .teamcard .memberimg {
  width: 100%;
  height: 100%;
  aspect-ratio: 3/4;
  object-fit: cover;
  object-position: top;
  overflow: hidden;
  box-sizing: content-box;
  transition: 0.3s;
}

.About_team_sec .teamdiv .teamcard .img-div:hover .memberimg {
  transform: scale(1.1);
  overflow: hidden;
  box-sizing: content-box;
  transition: 0.3s;
}

@media (min-width: 0px) and (max-width: 767.98px) {
  .About_team_sec .teamdiv .teamcard .detailsdiv .name {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .About_team_sec .teamdiv .teamcard .detailsdiv .position {
    font-size: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .About_team_sec .teamdiv .teamcard .detailsdiv .name {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .About_team_sec .teamdiv .teamcard .detailsdiv .position {
    font-size: 11px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .About_team_sec .teamdiv .teamcard .detailsdiv .name {
    font-size: 15px;
    margin-bottom: 0px;
  }
  .About_team_sec .teamdiv .teamcard .detailsdiv .position {
    font-size: 12px;
  }
}
