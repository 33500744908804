.Airport_Ladder_Rent_content
  .inner-tabs-holder
  .inner-tab-content-holder
  .inner-tab-heading
  h3 {
  margin-bottom: 0px;
}

.Airport_Ladder_Rent_content .main-table-holder .description-holder p {
  margin-bottom: 0px;
}

.Airport_Ladder_Rent_content .main-table-holder .weight-details-holder {
  margin-bottom: 15px;
}


.Airport_Ladder_Rent_content .tab-content-holder {
    margin-bottom: 25px;
}