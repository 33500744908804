.Contact_Us_Banner .banner-heading-holder {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
}

.Contact_Us_Banner .overlaycolor {
  background: linear-gradient(358deg, #fff 0%, rgb(255 255 255 / 0%) 39%);
}

@media (min-width: 0px) and (max-width: 320px) {
  .Contact_Us_Banner .banner-heading-holder {
    bottom: 25px;
  }

  .About_banner_sec .bannerimg {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/0.5;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Contact_Us_Banner .banner-heading-holder {
    bottom: 25px;
  }

  .About_banner_sec .bannerimg {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/0.4;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Contact_Us_Banner .banner-heading-holder {
    bottom: 25px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Contact_Us_Banner .banner-heading-holder {
    bottom: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Contact_Us_Banner .banner-heading-holder {
    top: 150px;
}
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Contact_Us_Banner .banner-heading-holder {
    top: 143px;
  }
}



@media (min-width: 1024px) and (max-width: 1199px) {
  .Contact_Us_Banner .banner-heading-holder {
    top: 200px;
}
}