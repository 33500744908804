.Contact_Us_content .Contact_Us_content_backgrounddiv {
  margin: 0% 0px 20px 0px;
}

.Contact_Us_content .heading-text {
  font-family: Raleway;
}

.Contact_Us_content .contact_form_holder {
  background: #fff0fc 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 8px 8px;
  padding: 30px 30px;
}

.Contact_Us_content .contact_form_holder .form-section .form-control {
  background: #fff;
  border: 1px solid #fff;
}

.Contact_Us_content .Contact_Us_content_backgrounddiv .abt-contdiv {
  margin-top: 0px;
}

.Contact_Us_content .Contact_Us_content_backgrounddiv {
  padding: 25px 50px;
}

.Contact_Us_content .info-section-holder {
  margin-top: 40px;
}

.Contact_Us_content .info-section-holder .info-holder .text-holder p {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.Contact_Us_content .info-section-holder .info-holder {
  display: flex;
  margin-bottom: 20px;
}

.Contact_Us_content .info-section-holder .info-holder .icon-holder {
  width: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.699999988079071px solid #7070703b;
  border-radius: 3px;
  padding: 0px 5px;
  height: fit-content;
  margin-right: 20px;
}

.Contact_Us_content .info-section-holder .icon-holder .icon {
  color: #8e1c79;
}

.Contact_Us_content .info-card-holder {
  margin-top: 50px;
}

.Contact_Us_content .info-card-holder .card-holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.699999988079071px solid #7070703b;
  border-radius: 6px;
  opacity: 1;
}

.Contact_Us_content .info-card-holder .card-holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.699999988079071px solid #7070703b;
  border-radius: 6px;
  padding: 0px 15px 15px 15px;
  margin-bottom: 20px;
}

.Contact_Us_content .info-card-holder .card-holder .text-holder {
  margin-top: 10px;
}

.Contact_Us_content .info-card-holder .card-holder .text-holder p {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.Contact_Us_content .info-card-holder .card-holder .top-text-holder {
  background: #8e1c79 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 7px 7px;
  width: fit-content;
  padding: 1px 10px;
}

.Contact_Us_content .info-card-holder .card-holder .top-text-holder p {
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0px;
}

.Contact_Us_content .info-card-holder .card-holder {
  margin-bottom: 30px;
}

.Contact_Us_content .map-holder {
  margin-top: 50px;
}

.Contact_Us_content .map-holder iframe {
  border: 0;
  width: 100%;
  height: 500px;
  border-radius: 10px;
}
@media (min-width: 0px) and (max-width: 320px) {
  .Contact_Us_content .contact_form_holder {
    padding: 30px 10px;
  }

  .Contact_Us_content .map-holder iframe {
    height: 200px;
  }

  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    padding: 25px 20px;
  }

  .Contact_Us_content .info-card-holder .card-holder .text-holder p {
    font-size: 12px;
  }

  .Contact_Us_content .info-card-holder .card-holder .top-text-holder p {
    font-size: 13px;
  }

  .Contact_Us_content .info-section-holder .info-holder .text-holder p {
    font-size: 13px;
  }

  .Contact_Us_content .info-section-holder .icon-holder .icon {
    font-size: 12px;
  }

  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    margin: 0px;
  }

  .Contact_Us_content .info-section-holder {
    margin-top: 20px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Contact_Us_content .contact_form_holder {
    padding: 30px 10px;
  }

  .Contact_Us_content .map-holder iframe {
    height: 200px;
  }

  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    padding: 25px 20px;
  }

  .Contact_Us_content .info-card-holder .card-holder .text-holder p {
    font-size: 12px;
  }

  .Contact_Us_content .info-card-holder .card-holder .top-text-holder p {
    font-size: 13px;
  }

  .Contact_Us_content .info-section-holder .info-holder .text-holder p {
    font-size: 13px;
  }

  .Contact_Us_content .info-section-holder .icon-holder .icon {
    font-size: 12px;
  }

  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    margin: 0px;
  }

  .Contact_Us_content .info-section-holder {
    margin-top: 20px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Contact_Us_content .contact_form_holder {
    padding: 30px 10px;
  }

  .Contact_Us_content .map-holder iframe {
    height: 200px;
  }

  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    padding: 25px 20px;
  }

  .Contact_Us_content .info-card-holder .card-holder .text-holder p {
    font-size: 12px;
  }

  .Contact_Us_content .info-card-holder .card-holder .top-text-holder p {
    font-size: 13px;
  }

  .Contact_Us_content .info-section-holder .info-holder .text-holder p {
    font-size: 13px;
  }

  .Contact_Us_content .info-section-holder .icon-holder .icon {
    font-size: 12px;
  }

  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    margin: 0px;
  }

  .Contact_Us_content .info-section-holder {
    margin-top: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Contact_Us_content .contact_form_holder {
    padding: 30px 10px;
  }

  .Contact_Us_content .map-holder iframe {
    height: 200px;
  }

  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    padding: 25px 20px;
  }

  .Contact_Us_content .info-section-holder .info-holder .text-holder p {
    font-size: 15px;
  }

  .Contact_Us_content .info-section-holder .icon-holder .icon {
    font-size: 12px;
  }

  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    margin: 0px;
  }

  .Contact_Us_content .info-section-holder {
    margin-top: 20px;
  }

  .banner-heading-holder {
    top: 110px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Contact_Us_content .contact_form_holder {
    padding: 30px 10px;
  }

  .Contact_Us_content .map-holder iframe {
    height: 300px;
  }

  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    padding: 25px 20px;
  }

  .Contact_Us_content .info-section-holder .info-holder .text-holder p {
    font-size: 15px;
  }

  .Contact_Us_content .info-section-holder .icon-holder .icon {
    font-size: 12px;
  }

  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    margin: 0% 0px 20px 0px;
  }

  .Contact_Us_content .info-card-holder .card-holder .text-holder p {
    font-size: 14px;
  }

  .Contact_Us_content .info-section-holder {
    margin-top: 20px;
  }

  .Contact_Us_content .info-card-holder .card-holder .top-text-holder p {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 0px;
  }

  .banner-heading-holder {
    top: 177px;
}
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    margin: 0% 0px 20px 0px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Contact_Us_content .Contact_Us_content_backgrounddiv {
    margin: 0% 0px 20px 0px;
  }
}
