.Projects_content .project-card-holder .project-card {
  margin-bottom: 15px;
}

.Projects_content .project-card-holder .project-card .img-holder .projectimg {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/0.7;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  object-fit: cover;
  background: #f5f5f5;
}

.Projects_content
  .project-card-holder
  .project-card
  .img-holder
  .projectimg:hover {
  margin-top: -8px;
  margin-bottom: 8px;
  transition: all 0.3s ease-in-out;
}

.Projects_content .project-card-holder .project-card:hover .text-holder h4 {
  color: #c909a6;
}

.Projects_content .project-card-holder .project-card .text-holder {
  padding: 15px 0px;
}

.Projects_content .project-card-holder .project-card .text-holder h4 {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.Projects_content .project-card-holder .project-card .text-holder p {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.Projects_content
  .project-card-holder
  .project-card
  .text-holder
  .location-icon {
  margin-right: 10px;
}

.Projects_content .Terms_Conditions_content_backgrounddiv {
  box-shadow: none;
  margin-bottom: 0%;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Projects_content .project-card-holder .project-card .text-holder h4 {
    font-size: 15px;
  }

  .Projects_content .project-card-holder .project-card .text-holder p {
    font-size: 12px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Projects_content .project-card-holder .project-card .text-holder h4 {
    font-size: 15px;
  }

  .Projects_content .project-card-holder .project-card .text-holder p {
    font-size: 12px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
}

@media (min-width: 576px) and (max-width: 767px) {
  .Projects_content .project-card-holder .project-card .text-holder h4 {
    font-size: 15px;
  }

  .Projects_content .project-card-holder .project-card .text-holder p {
    font-size: 12px;
  }

  .Projects_content .Terms_Conditions_content_backgrounddiv {
    margin: -50px 0px 20px 0px;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .Projects_content .Terms_Conditions_content_backgrounddiv {
    margin: -100px 0px 20px 0px;
  }
  }
  

@media (min-width: 992px) and (max-width: 1023px) {
.Projects_content .Terms_Conditions_content_backgrounddiv {
  margin: -100px 0px 20px 0px;
}
}

@media (min-width: 1200px) and (max-width: 1299px) {
.Projects_content .Terms_Conditions_content_backgrounddiv {
  margin: -100px 0px 20px 0px;
}
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .Projects_content .Terms_Conditions_content_backgrounddiv {
    margin: -100px 0px 20px 0px;
  }
  }
  

  @media (min-width: 1400px) and (max-width: 1599px) {
    .Projects_content .Terms_Conditions_content_backgrounddiv {
      margin: -100px 0px 20px 0px;
    }
    }
    