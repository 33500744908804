.inner-text-holder li {
  list-style: none;
}

.inner-text-holder-padding ul {
  padding-left: 0px;
}

.list-text-holder li {
  list-style: none;
}
