.HomeBanner {
  position: relative;
  top: 0;
  width: 100%;
}

.HomeBanner .HomeBanner_vdo_holder .HomeBanner_vdo {
  width: 100%;
  height: 100%;
}

.HomeBanner .banner-content-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.HomeBanner .HomeBanner_content_holder {
  background: #0000003b;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  padding: 10px 35px 55px 35px;
  position: relative;
}

.HomeBanner .HomeBanner_content_holder::before {
  content: "";
  border-right: 4px solid #fff;
  height: 90%;
  left: -20px;
  top: -30px;
  position: absolute;
}

.HomeBanner .HomeBanner_content_holder::after {
  content: "";
  border-right: 4px solid #fff;
  height: 90%;
  right: -20px;
  top: -30px;
  position: absolute;
}

.HomeBanner .HomeBanner_content_holder .hr-line {
  position: absolute;
  width: 100%;
  top: -40px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HomeBanner .HomeBanner_content_holder .hr-line::after {
  content: "";
  border-top: 4px solid #fff;
  width: 30%;
  left: -20px;
  position: absolute;
}

.HomeBanner .HomeBanner_content_holder .hr-line::before {
  content: "";
  border-top: 4px solid #fff;
  width: 30%;
  right: -20px;
  position: absolute;
}

.HomeBanner .HomeBanner_content_holder .hr-line .text-holder {
  display: flex;
  justify-content: center;
}

.HomeBanner .HomeBanner_content_holder .hr-line .text-holder p {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000029;
  margin-bottom: 0px;
}

.HomeBanner .HomeBanner_content_holder .hr-line .text-holder .vr {
  background: #fff;
  opacity: 1;
  width: 2px;
  margin: 0px 20px;
}

.HomeBanner .overlay-holder {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: transparent
    linear-gradient(1deg, #000000d1 0%, #00000085 40%, #00000000 100%) 0% 0%
    no-repeat padding-box;
  height: 100%;
}

.HomeBanner .HomeBanner_content_holder .banner-header-holder {
  position: relative;
}

.HomeBanner .HomeBanner_content_holder .banner-header-holder .btn-holder {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 115%;
}

.HomeBanner .HomeBanner_content_holder .banner-header-holder h3 {
  text-align: center;
  font-size: 70px;
  font-weight: 800;
  color: #ffffff;
  text-shadow: 2px -2px 6px #9c27b0;
}

.HomeBanner .HomeBanner_content_holder .banner-header-holder p {
  text-align: center;
  font-size: 21px;
  font-weight: 500;
  color: #ffffff;
  margin-top: 25px;
  margin-bottom: 0px;
}

.HomeBanner
  .HomeBanner_content_holder
  .banner-header-holder
  .btn-holder
  .contact-btn {
    /* background: #ffffff; */
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #8e1c79;
    width: 75%;
    margin: 0px auto;
}

.HomeBanner
  .HomeBanner_content_holder
  .banner-header-holder
  .btn-holder
  .contact-btn p {
    color: #8e1c79;
    margin: 0px;
    
}

.HomeBanner
  .HomeBanner_content_holder
  .banner-header-holder
  .btn-holder
  .contact-btn:hover p {
    color: #fff;
    
}

@media (min-width: 0px) and (max-width: 280px) {
  .HomeBanner .banner-content-main {
    top: 65%;
    width: 80%;
  }

  .HomeBanner .HomeBanner_content_holder {
    padding: 10px 35px 35px 35px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder h3 {
    font-size: 14px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line {
    top: -20px;
  }

  .HomeBanner .HomeBanner_content_holder::before {
    top: -10px;
  }

  .HomeBanner .HomeBanner_content_holder::after {
    top: -10px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line .text-holder p {
    font-size: 13px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line .text-holder .vr {
    margin: 0px 10px;
  }

  .HomeBanner .HomeBanner_content_holder {
    padding: 10px 10px 25px 10px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder p {
    font-size: 12px;
    margin-top: 5px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::after {
    width: 12%;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::before {
    width: 12%;
  }

  .HomeBanner
    .HomeBanner_content_holder
    .banner-header-holder
    .btn-holder
    .contact-btn {
    font-size: 12px;
    padding: 3px 0px;
  }
}

@media (min-width: 281px) and (max-width: 320px) {
  .HomeBanner .banner-content-main {
    top: 65%;
    width: 80%;
  }

  .HomeBanner .HomeBanner_content_holder {
    padding: 10px 35px 35px 35px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder h3 {
    font-size: 14px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line {
    top: -20px;
  }

  .HomeBanner .HomeBanner_content_holder::before {
    top: -10px;
  }

  .HomeBanner .HomeBanner_content_holder::after {
    top: -10px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line .text-holder p {
    font-size: 13px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line .text-holder .vr {
    margin: 0px 10px;
  }

  .HomeBanner .HomeBanner_content_holder {
    padding: 10px 10px 25px 10px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder p {
    font-size: 12px;
    margin-top: 5px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::after {
    width: 12%;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::before {
    width: 12%;
  }

  .HomeBanner
  .HomeBanner_content_holder
  .banner-header-holder
  .btn-holder
  .contact-btn {
  font-size: 12px;
  padding: 12px 0px;
}
}

@media (min-width: 321px) and (max-width: 486px) {
  .HomeBanner .banner-content-main {
    top: 65%;
    width: 80%;
  }

  .HomeBanner .HomeBanner_content_holder {
    padding: 10px 35px 35px 35px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder h3 {
    font-size: 14px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line {
    top: -20px;
  }

  .HomeBanner .HomeBanner_content_holder::before {
    top: -10px;
  }

  .HomeBanner .HomeBanner_content_holder::after {
    top: -10px;
  }
  .HomeBanner .HomeBanner_content_holder .hr-line .text-holder p {
    font-size: 13px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line .text-holder .vr {
    margin: 0px 10px;
  }

  .HomeBanner .HomeBanner_content_holder {
    padding: 10px 10px 25px 10px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder p {
    font-size: 12px;
    margin-top: 5px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::after {
    width: 12%;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::before {
    width: 12%;
  }

  .HomeBanner
  .HomeBanner_content_holder
  .banner-header-holder
  .btn-holder
  .contact-btn {
  font-size: 12px;
  padding: 13px 0px;
}
}

@media (min-width: 486px) and (max-width: 575px) {
  .HomeBanner .banner-content-main {
    top: 65%;
    width: 70%;
  }

  .HomeBanner .HomeBanner_content_holder {
    padding: 10px 35px 35px 35px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder h3 {
    font-size: 20px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line .text-holder p {
    font-size: 14px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder p {
    font-size: 14px;
    margin-top: 10px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::after {
    width: 12%;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::before {
    width: 12%;
  }

  .HomeBanner
  .HomeBanner_content_holder
  .banner-header-holder
  .btn-holder
  .contact-btn {
  font-size: 15px;
  padding: 19px 0px;
}
}

@media (min-width: 576px) and (max-width: 767px) {
  .HomeBanner .banner-content-main {
    top: 65%;
  }

  .HomeBanner .HomeBanner_content_holder {
    padding: 10px 35px 35px 35px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder h3 {
    font-size: 20px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line .text-holder p {
    font-size: 14px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder p {
    font-size: 14px;
    margin-top: 10px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::after {
    width: 12%;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::before {
    width: 12%;
  }

  .HomeBanner
  .HomeBanner_content_holder
  .banner-header-holder
  .btn-holder
  .contact-btn {
  font-size: 15px;
  padding: 17px 0px;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .HomeBanner .banner-content-main {
    top: 65%;
  }

  .HomeBanner .HomeBanner_content_holder {
    padding: 10px 35px 40px 35px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder h3 {
    font-size: 30px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder p {
    font-size: 16px;
    margin-top: 10px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::after {
    width: 17%;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::before {
    width: 17%;
  }

  .HomeBanner
  .HomeBanner_content_holder
  .banner-header-holder
  .btn-holder
  .contact-btn {
  font-size: 15px;
  padding: 20px 0px;
}
}

@media (min-width: 992px) and (max-width: 1023px) {
  .HomeBanner .banner-content-main {
    top: 65%;
  }

  .HomeBanner .HomeBanner_content_holder {
    padding: 10px 35px 38px 35px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder h3 {
    font-size: 30px;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder p {
    font-size: 16px;
    margin-top: 10px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::after {
    width: 20%;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::before {
    width: 20%;
  }

  .HomeBanner
  .HomeBanner_content_holder
  .banner-header-holder
  .btn-holder
  .contact-btn {
  font-size: 15px;
  padding: 20px 0px;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .HomeBanner .HomeBanner_content_holder .banner-header-holder h3 {
    font-size: 40px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::before {
    width: 20%;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::after {
    width: 20%;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder p {
    font-size: 21px;
    margin-top: 12px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .HomeBanner .HomeBanner_content_holder .banner-header-holder h3 {
    font-size: 50px;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::before {
    width: 20%;
  }

  .HomeBanner .HomeBanner_content_holder .hr-line::after {
    width: 20%;
  }

  .HomeBanner .HomeBanner_content_holder .banner-header-holder p {
    font-size: 18px;
    margin-top: 13px;
  }
}
