.main-banner-heading-holder {
  padding: 0px 7px;
}

.main-banner-heading-holder h3 {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #8e1c79;
}



@media (min-width: 0px) and (max-width: 320px) {
  .main-banner-heading-holder h3 {
    font-size: 18px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .main-banner-heading-holder h3 {
    font-size: 18px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .main-banner-heading-holder h3 {
    font-size: 18px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .main-banner-heading-holder h3 {
    font-size: 22px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-banner-heading-holder h3 {
    font-size: 22px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .main-banner-heading-holder h3 {
    /* font-size: 22px; */
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .main-banner-heading-holder h3 {
    /* font-size: 22px; */
  }
}
