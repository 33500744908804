.OurProfessionalism {
  position: relative;
  margin-top: 7%;
  padding-top: 70px;
}

.OurProfessionalism .OurProfessionalism_holder {
  background: transparent linear-gradient(270deg, #ffffff 0%, #f8e5f4 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
}

.OurProfessionalism .OurProfessionalism_holder .content_holder {
  padding: 40px 0px 60px 50px;
}

.OurProfessionalism .OurProfessionalism_holder .content_holder .heading_holder {
  margin-bottom: 40px;
}

.OurProfessionalism
  .OurProfessionalism_holder
  .content_holder
  .heading_holder
  .heading-text {
  text-align: left;
}

.OurProfessionalism .OurProfessionalism_holder .content_holder .text_holder {
  margin-bottom: 50px;
}

.OurProfessionalism .OurProfessionalism_holder .content_holder .text_holder p {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.OurProfessionalism
  .OurProfessionalism_holder
  .overlay-img-holder
  .overlay-imgs {
  display: flex;
  justify-content: end;
}

.OurProfessionalism
  .OurProfessionalism_holder
  .overlay-img-holder
  .img-holder
  .professional_img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  aspect-ratio: 1/0.9;
}

.OurProfessionalism .OurProfessionalism_holder .overlay-img-holder {
  position: relative;
}

.OurProfessionalism
  .OurProfessionalism_holder
  .overlay-img-holder
  .smaller-img-holder {
  position: absolute;
  left: 80px;
  bottom: 0;
  width: 40%;
  padding: 0px;
  border: 10px solid #fff;
  border-radius: 10px;
  background: #fff;
}

.OurProfessionalism .bg-heading-holder {
  position: absolute;
  top: 0px;
  z-index: -1;
  display: none;
}

.OurProfessionalism .bg-heading-holder h3 {
  text-align: left;
  font-size: 80px;
  font-weight: 800;
  color: #000000;
  opacity: 0.06;
}

.OurProfessionalism
  .OurProfessionalism_holder
  .overlay-img-holder
  .overlay-text-img-holder {
  position: relative;
}

.OurProfessionalism
  .OurProfessionalism_holder
  .overlay-img-holder
  .overlay-text-img-holder
  .vertical-text-holder {
  position: absolute;
  transform: rotate(90deg);
  z-index: 1;
  top: 50%;
  right: -25%;
  bottom: 50%;
  width: fit-content;
  height: fit-content;
}

.OurProfessionalism
  .OurProfessionalism_holder
  .overlay-img-holder
  .overlay-text-img-holder
  .vertical-text-holder
  h4 {
  font-size: 41px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #fff;
  opacity: 0.5;
}

@media (min-width: 0px) and (max-width: 280px) {
  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .heading_holder
    .heading-text {
    text-align: center;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder {
    padding: 20px 15px;
  }

  .OurProfessionalism {
    padding-top: 30px;
  }

  .OurProfessionalism .bg-heading-holder h3 {
    font-size: 30px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .heading_holder {
    margin-bottom: 12px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .text_holder
    p {
    text-align: center;
    font-size: 12px;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder .text_holder {
    margin-bottom: 15px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder
    h4 {
    font-size: 12px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder {
    top: 41%;
    right: -17%;
  }
}

@media (min-width: 281px) and (max-width: 320px) {
  .OurProfessionalism .OurProfessionalism_holder .content_holder {
    padding: 20px 15px;
  }

  .OurProfessionalism {
    padding-top: 30px;
  }

  .OurProfessionalism .bg-heading-holder h3 {
    font-size: 30px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .heading_holder
    .heading-text {
    text-align: center;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .heading_holder {
    margin-bottom: 12px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .text_holder
    p {
    text-align: center;
    font-size: 12px;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder .text_holder {
    margin-bottom: 15px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-imgs {
    justify-content: center;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .smaller-img-holder {
    left: 60px;
    width: 26%;
    border: 4px solid #fff;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder
    h4 {
    font-size: 12px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder {
    top: 41%;
    right: -17%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .OurProfessionalism .OurProfessionalism_holder .content_holder {
    padding: 20px 15px;
  }

  .OurProfessionalism {
    padding-top: 30px;
  }

  .OurProfessionalism .bg-heading-holder {
    top: 7px;
  }


  .OurProfessionalism .bg-heading-holder h3 {
    font-size: 27px;
  }

  
  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .heading_holder
    .heading-text {
    text-align: center;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .heading_holder {
    margin-bottom: 12px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .text_holder
    p {
    text-align: center;
    font-size: 12px;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder .text_holder {
    margin-bottom: 15px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-imgs {
    justify-content: center;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .smaller-img-holder {
    left: 60px;
    width: 26%;
    border: 4px solid #fff;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder
    h4 {
    font-size: 12px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder {
    top: 41%;
    right: -19%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-imgs {
    justify-content: center;
  }

  .OurProfessionalism {
    padding-top: 40px;
  }

  .OurProfessionalism .bg-heading-holder h3 {
    font-size: 45px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .smaller-img-holder {
    left: 50px;
    width: 30%;
    border: 5px solid #fff;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder {
    padding: 20px 0px 20px 20px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .heading_holder {
    margin-bottom: 10px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .text_holder
    p {
    font-size: 12px;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder .text_holder {
    margin-bottom: 20px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder
    h4 {
    font-size: 18px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder {
    top: 41%;
    right: -20%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-imgs {
    justify-content: center;
  }

  .OurProfessionalism {
    padding-top: 40px;
  }

  .OurProfessionalism .bg-heading-holder h3 {
    font-size: 45px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .smaller-img-holder {
    left: 50px;
    width: 30%;
    border: 5px solid #fff;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder {
    padding: 20px 0px 20px 20px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .heading_holder {
    margin-bottom: 10px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .text_holder
    p {
    font-size: 12px;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder .text_holder {
    margin-bottom: 20px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder
    h4 {
    font-size: 18px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder {
    top: 41%;
    right: -20%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .OurProfessionalism {
    padding-top: 40px;
  }

  .OurProfessionalism .bg-heading-holder h3 {
    font-size: 45px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .smaller-img-holder {
    left: 49px;
    width: 39%;
    border: 5px solid #fff;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder {
    padding: 20px 0px 20px 20px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .heading_holder {
    margin-bottom: 10px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .text_holder
    p {
    font-size: 12px;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder .text_holder {
    margin-bottom: 20px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder
    h4 {
    font-size: 18px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder {
    top: 41%;
    right: -20%;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .OurProfessionalism {
    padding-top: 65px;
  }

  .OurProfessionalism .bg-heading-holder h3 {
    font-size: 70px;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder {
    padding: 15px 0px 20px 23px;
  }
  .common-btn-holder .common_btn {
    padding: 7px 0px;
  }

  .OurProfessionalism .OurProfessionalism_holder .content_holder .text_holder {
    margin-bottom: 20px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .heading_holder {
    margin-bottom: 20px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder
    h4 {
    font-size: 25px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder {
    top: 41%;
    right: -25%;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .OurProfessionalism .OurProfessionalism_holder .content_holder {
    padding: 15px 0px 20px 23px;
  }


  .OurProfessionalism .OurProfessionalism_holder .content_holder .text_holder {
    margin-bottom: 20px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .content_holder
    .heading_holder {
    margin-bottom: 20px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder
    h4 {
    font-size: 25px;
  }

  .OurProfessionalism
    .OurProfessionalism_holder
    .overlay-img-holder
    .overlay-text-img-holder
    .vertical-text-holder {
    top: 41%;
    right: -25%;
  }
}
