.Services_slider_section .big-img-holder {
  border: 0.6000000238418579px solid #707070;
  border-radius: 11px;
  height: 100%;
}

.Services_slider_section .big-img-holder .Servive-img {
  width: 100%;
  height: 100%;
  border-radius: 11px;
  aspect-ratio: 1/1;
  /* object-fit: contain; */
  /* background: #f8f9fa; */
}

.Services_slider_section .Services_slider .big-img-holder {
  margin-bottom: 15px;
  width: 100%;
  height: 100%;
}

.Services_slider_section .Services_slider {
  height: 340px;
  padding: 0px 0px;
}

.Services_slider_section .silder-btn {
  position: relative;
}

.Services_slider_section .silder-btn .back-btn {
  width: 15px;
  height: 15px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 334px;
  z-index: 99;
  left: 0px;
  right: 0px;
  transform: rotate(90deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  margin: 0px auto;
  background: #fbeaf8 0% 0% no-repeat padding-box;
  padding: 3px 5px;
  border-radius: 50%;
  font-size: 11px;
}

.Services_slider_section .silder-btn .next-btn {
  width: 15px;
  height: 15px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: -12px;
  z-index: 99;
  left: 0px;
  right: 0px;
  transform: rotate(90deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  margin: 0px auto;
  background: #fbeaf8 0% 0% no-repeat padding-box;
  padding: 3px 5px;
  border-radius: 50%;
  font-size: 11px;
}

.Services_slider_section .Services_slider .swiper-button-prev,
.Services_slider_section .Services_slider .swiper-button-next {
  display: none;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Services_slider_section .Services_slider {
    height: 200px;
  }

  .Services_slider_section .silder-btn .back-btn {
    bottom: 195px;
  }

  .Services_slider_section .big-img-holder {
    border-radius: 5px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Services_slider_section .big-img-holder {
    border-radius: 5px;
  }

  .Services_slider_section .Services_slider {
    height: 330px;
  }

  .Services_slider_section .silder-btn .back-btn {
    bottom: 326px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Services_slider_section .big-img-holder {
    border-radius: 5px;
  }

  .Services_slider_section .Services_slider {
    height: 400px;
  }

  .Services_slider_section .silder-btn .back-btn {
    bottom: 390px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Services_slider_section .big-img-holder {
    border-radius: 5px;
  }

  .Services_slider_section .Services_slider {
    height: 400px;
  }

  .Services_slider_section .silder-btn .back-btn {
    bottom: 390px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Services_slider_section .Services_slider {
    height: 220px;
    padding: 0px 0px;
  }

  .Services_slider_section .silder-btn .back-btn {
    bottom: 215px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Services_slider_section .Services_slider {
    height: 220px;
    padding: 0px 0px;
  }

  .Services_slider_section .silder-btn .back-btn {
    bottom: 215px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Services_slider_section .Services_slider {
    height: 220px;
    padding: 0px 0px;
  }

  .Services_slider_section .silder-btn .back-btn {
    bottom: 215px;
  }
}
