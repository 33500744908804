.FRP_scaffolding_content .outer-tab-content {
  margin-top: 0px;
}

.FRP_scaffolding_content .outer-tab-content .inner-heading-text {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-transform: inherit;
}


.FRP_scaffolding_content .outer-tab-content .text-content {
    padding-left: 15px;
}

.FRP_scaffolding_content .outer-tab-content .tab-content-holder .order-list-holder  li {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    text-transform: inherit;
    list-style: decimal-leading-zero;
}

.FRP_scaffolding_content .inner-tabs-holder {
    margin-top: 40px;
}