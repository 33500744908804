.About_banner_sec {
    position: relative;
}

.About_banner_sec .bannerimg {
    height: 100%;
    width: 100%;
    aspect-ratio: 1/0.3;
    /* object-fit: cover; */
}

.About_banner_sec .overlaycolor {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(0deg , #fff , transparent );
    height: 300px;
}