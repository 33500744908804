.Studio_big_slider_modal .slider-main .slider-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/0.5;
  /* box-shadow: 0px 0px 100px 0px #fff; */
  border-radius: 10px;
}

.Studio_big_slider_modal {
  background: #ffffff30 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

.Studio_big_slider_modal .slider-main {
  border-radius: 10px;
}

.Studio_big_slider_modal .modal-content {
  background-color: transparent;
  border: 0px;
}

.Studio_big_slider_modal .swiper-wrapper {
  /* padding: 50px 10px; */
}

.Studio_big_slider_modal .modal-header {
  border: none;
  background: none;
  position: absolute;
  top: -190px;
  right: 0px;
}

.Studio_big_slider_modal .btn-close {
  filter: invert(1);
  opacity: 1 !important;
  color: #fff !important;
}

.Studio_big_slider_modal .btn-close:focus {
  box-shadow: none;
}

.Studio_big_slider_modal {
  --bs-modal-width: 100% I !important;
}

.Studio_big_slider_modal .main-slider .swiper-slide-active {
  z-index: 999;
  transform: scale(1.8);
}

.Studio_big_slider_modal .swiper {
  /* padding: 5% 0%; */
  overflow: visible;
}

.Studio_big_slider_modal .main-slider .swiper-slide-next {
  transform: scale(1.2);
}

.Studio_big_slider_modal .main-slider .swiper-slide-next .slide-heading-holder {
  display: none;
}

.Studio_big_slider_modal .main-slider .swiper-slide-next .text-holder p {
  display: none;
}

.Studio_big_slider_modal .main-slider .swiper-slide-prev {
  transform: scale(1.2);
}

.Studio_big_slider_modal .main-slider .swiper-slide-prev .slide-heading-holder {
  display: none;
}

.Studio_big_slider_modal .main-slider .swiper-slide-prev .text-holder p {
  display: none;
}

.Studio_big_slider_modal .main-slider .swiper-button-prev {
  left: 21%;
}

.Studio_big_slider_modal .swiper-button-next {
  right: 21%;
}

.Studio_big_slider_modal .slide-heading-holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  text-align: center;
  padding: 5px 0px;
  width: 80%;
  margin: 0px auto;
}

.Studio_big_slider_modal .slide-heading-holder h3 {
  font-size: 13px;
  font-weight: 500;
  color: #8e1c79;
  margin-bottom: 0px;
}

.Studio_big_slider_modal .main-slider-img-holder {
  position: relative;
  overflow: hidden;
}

.Studio_big_slider_modal .main-slider-img-holder .overlay-holder {
  background: transparent linear-gradient(0deg, #000000 0%, #54545400 100%) 0%
    0% no-repeat padding-box;
  position: absolute;
  height: 20%;
  z-index: 0;
  width: 100%;
  bottom: 0;
  border-radius: 10px;
}

.Studio_big_slider_modal .main-slider-img-holder .text-holder {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1;
}

.Studio_big_slider_modal .main-slider-img-holder .text-holder .location-icon {
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  margin-right: 15px;
}

.Studio_big_slider_modal .main-slider-img-holder .text-holder p {
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0px;
}

.Studio_big_slider_modal
  .img-list-holder
  .img-list-slider
  .img-list-main
  .img-list-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.Studio_big_slider_modal .img-list-holder {
  position: absolute;
  /* top: 0; */
  left: 0;
  right: 0px;
  bottom: -53%;
  overflow: hidden;
  width: 50%;
  margin: 0px auto;
}

@media (min-width: 0px) and (max-width: 575px) {
  .Studio_big_slider_modal .main-slider .swiper-slide-active {
    transform: scale(2.5);
  }

  .Studio_big_slider_modal .main-slider .slider-main .slider-img {
    border-radius: 5px;
  }

  .Studio_big_slider_modal .main-slider .swiper-button-next {
    width: 25px;
    height: 25px;
    bottom: 28%;
    right: 11%;
  }

  .Studio_big_slider_modal .main-slider .swiper-button-prev {
    width: 25px;
    height: 25px;
    bottom: 28%;
    left: 11%;
  }
}

@media (min-width: 0px) and (max-width: 320px) {
  .Studio_big_slider_modal .main-slider .swiper-slide-active {
    z-index: 999;
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-slide-prev {
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-slide-next {
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-button-next {
    right: 0px;
  }

  .Studio_big_slider_modal .main-slider .swiper-button-prev {
    left: 0px;
  }

  .Studio_big_slider_modal .img-list-holder {
    bottom: -15%;
    width: 90%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Studio_big_slider_modal .main-slider .swiper-slide-active {
    z-index: 999;
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-slide-prev {
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-slide-next {
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-button-next {
    right: 0px;
  }

  .Studio_big_slider_modal .main-slider .swiper-button-prev {
    left: 0px;
  }

 

  .Studio_big_slider_modal .img-list-holder {
    bottom: -15%;
    width: 90%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Studio_big_slider_modal .main-slider .swiper-slide-active {
    z-index: 999;
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-slide-prev {
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-slide-next {
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-button-next {
    right: 0px;
  }

  .Studio_big_slider_modal .main-slider .swiper-button-prev {
    left: 0px;
  }

 

  .Studio_big_slider_modal .img-list-holder {
    bottom: -15%;
    width: 90%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Studio_big_slider_modal .main-slider-img-holder .text-holder .location-icon {
    font-size: 14px;
  }

  .Studio_big_slider_modal .main-slider-img-holder .text-holder p {
    font-size: 14px;
  }
  .Studio_big_slider_modal .main-slider .swiper-slide-active {
    z-index: 999;
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-slide-prev {
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-slide-next {
    transform: none;
  }

  .Studio_big_slider_modal .main-slider .swiper-button-next {
    right: 0px;
  }

  .Studio_big_slider_modal .main-slider .swiper-button-prev {
    left: 0px;
  }

  .Studio_big_slider_modal .img-list-holder {
    bottom: -15%;
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Studio_big_slider_modal .slide-heading-holder h3 {
    font-size: 8px;
  }

  .Studio_big_slider_modal .slide-heading-holder {
    padding: 2px 0px;
  }

  .Studio_big_slider_modal .main-slider-img-holder .text-holder p {
    font-size: 7px;
  }

  .Studio_big_slider_modal .main-slider-img-holder .text-holder .location-icon {
    font-size: 7px;
    margin-right: 4px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Studio_big_slider_modal .slide-heading-holder h3 {
    font-size: 9px;
  }

  .Studio_big_slider_modal .slide-heading-holder {
    padding: 2px 0px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Studio_big_slider_modal .slide-heading-holder h3 {
    font-size: 9px;
  }

  .Studio_big_slider_modal .slide-heading-holder {
    padding: 2px 0px;
  }
}
