.Terms_Conditions_content .content-holder ::marker {
  font-weight: 600;
  font-size: 15px;
}

.Terms_Conditions_content .content-holder h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #000000;
}

.Terms_Conditions_content .content-holder p {
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
}

.Terms_Conditions_content .Terms_Conditions_content_backgrounddiv {
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 5%;
}

@media (min-width: 0px) and (max-width: 280px) {
  .Terms_Conditions_content .Terms_Conditions_content_backgrounddiv {
    margin-top: -8%;
  }
}

@media (min-width: 281px) and (max-width: 320px) {
  .Terms_Conditions_content .Terms_Conditions_content_backgrounddiv {
    margin-top: -8%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Terms_Conditions_content .Terms_Conditions_content_backgrounddiv {
    margin-top: -8%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Terms_Conditions_content .Terms_Conditions_content_backgrounddiv {
    margin-top: -8%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Terms_Conditions_content .Terms_Conditions_content_backgrounddiv {
    margin-top: -8%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Terms_Conditions_content .Terms_Conditions_content_backgrounddiv {
    margin-top: -8%;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Terms_Conditions_content .Terms_Conditions_content_backgrounddiv {
    margin-top: -8%;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Terms_Conditions_content .Terms_Conditions_content_backgrounddiv {
    margin-top: -8%;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .Terms_Conditions_content .Terms_Conditions_content_backgrounddiv {
    margin-top: -8%;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .Terms_Conditions_content .Terms_Conditions_content_backgrounddiv {
    margin-top: -8%;
  }
}
