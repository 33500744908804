.Customer_Satisfaction_Stories .Customer_Satisfaction_Stories_holder {
  background-image: url("/public/assets/Images/Home/Customer_Satisfaction/customer-satisfaction-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 50px 0px 50px 0px;
  border-radius: 10px;
}

.Customer_Satisfaction_Stories_holder .heading_holder {
  text-align: center;
  margin-bottom: 20px;
}

.Customer_Satisfaction_Stories_holder .heading_holder .heading-text {
  color: #fff;
  font-weight: 500;
  margin-bottom: 20px;
}

.Customer_Satisfaction_Stories_holder .heading_holder .subheading {
  color: #fff;
  font-weight: 500;
}

.Stories_slider_holder .Stories_slider {
  padding: 0px 30px 0px 30px;
}

.Stories_slider_holder .Stories_slider .swiper-button-prev {
  left: 0;
  border-radius: 0px 5px 5px 0px;
  padding: 35px 0px;
  background-color: #fff;
  top: 45%;
}

.Stories_slider_holder .Stories_slider .swiper-button-next {
  right: 0;
  border-radius: 5px 0 0 5px;
  padding: 35px 0px;
  background-color: #fff;
  top: 45%;
}

.Stories_slider_holder .Stories_slider .swiper-button-prev:hover::after {
  color: #000;
}

.Stories_slider_holder .Stories_slider .swiper-button-next:hover::after {
  color: #000;
}

.Stories_slider_holder .Stories_slider .Stories_card_holder {
  padding: 30px 15px 25px 15px;
}

.Stories_slider_holder .Stories_slider .Stories_card_holder .content-holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 25px 15px 8px 15px;
  height: 200px;
}

.Stories_slider_holder .Stories_slider .Stories_card_holder {
  position: relative;
}

.Stories_slider_holder
  .Stories_slider
  .Stories_card_holder
  .story-text-holder
  p {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
}

.Stories_slider_holder
  .Stories_slider
  .Stories_card_holder
  .story-name-text-holder {
  width: 80%;
  margin-left: auto;
}

.Stories_slider_holder
  .Stories_slider
  .Stories_card_holder
  .story-name-text-holder
  p {
  text-align: end;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.14px;
  color: #8e1c79;
  margin-bottom: 0px;
}

.Stories_slider_holder .Stories_slider .Stories_card_holder .profile-holder {
  position: absolute;
  bottom: 0px;
  left: 35px;
  border-radius: 50px;
  border: 5px solid #6a1b9a;
}

.Stories_slider_holder
  .Stories_slider
  .Stories_card_holder
  .profile-holder
  .img-holder {
  border-radius: 50px;
  width: fit-content;
}

.Stories_slider_holder
  .Stories_slider
  .Stories_card_holder
  .profile-holder
  .img-holder
  .Our_Services_img {
  width: 60px;
  height: 60px;
  border-radius: 50px;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Customer_Satisfaction_Stories .Customer_Satisfaction_Stories_holder {
    padding: 10px 0px 0px 0px;
  }

  .Stories_slider_holder .Stories_slider {
    padding-bottom: 15px;
  }

  .Customer_Satisfaction_Stories_holder .heading_holder {
    text-align: center;
    margin-bottom: 0px;
  }

  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .story-name-text-holder
    p {
    text-align: end;
    font-size: 11px;
  }
  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .story-text-holder
    p {
    font-size: 12px;
  }

  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .profile-holder
    .img-holder
    .Our_Services_img {
    width: 50px;
    height: 50px;
  }

  .Stories_slider_holder .Stories_slider .Stories_card_holder .profile-holder {
    left: 28px;
  }

  .Stories_slider_holder .Stories_slider .Stories_card_holder .content-holder {
    padding: 25px 15px 35px 15px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Customer_Satisfaction_Stories .Customer_Satisfaction_Stories_holder {
    padding: 10px 0px 0px 0px;
  }

  .Stories_slider_holder .Stories_slider {
    padding-bottom: 15px;
  }

  .Customer_Satisfaction_Stories_holder .heading_holder {
    text-align: center;
    margin-bottom: 0px;
  }

  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .story-name-text-holder
    p {
    text-align: end;
    font-size: 11px;
  }
  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .story-text-holder
    p {
    font-size: 12px;
  }

  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .profile-holder
    .img-holder
    .Our_Services_img {
    width: 50px;
    height: 50px;
  }

  .Stories_slider_holder .Stories_slider .Stories_card_holder .profile-holder {
    left: 28px;
  }

  .Stories_slider_holder .Stories_slider .Stories_card_holder .content-holder {
    padding: 25px 15px 35px 15px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Customer_Satisfaction_Stories .Customer_Satisfaction_Stories_holder {
    padding: 10px 0px 0px 0px;
  }

  .Stories_slider_holder .Stories_slider {
    padding-bottom: 15px;
  }

  .Customer_Satisfaction_Stories_holder .heading_holder {
    text-align: center;
    margin-bottom: 0px;
  }

  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .story-name-text-holder
    p {
    text-align: end;
    font-size: 12px;
  }
  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .story-text-holder
    p {
    font-size: 12px;
  }

  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .profile-holder
    .img-holder
    .Our_Services_img {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .story-name-text-holder
    p {
    text-align: end;
    font-size: 12px;
  }
  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .story-text-holder
    p {
    font-size: 12px;
  }

  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .profile-holder
    .img-holder
    .Our_Services_img {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .story-name-text-holder
    p {
    text-align: end;
    font-size: 12px;
  }
  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .story-text-holder
    p {
    font-size: 12px;
  }

  .Stories_slider_holder
    .Stories_slider
    .Stories_card_holder
    .profile-holder
    .img-holder
    .Our_Services_img {
    width: 50px;
    height: 50px;
  }
}
