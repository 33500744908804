.About_whyschooseus {
    padding-bottom: 5%;
}

.About_whyschooseus .choosecont {
    margin-top: 70px;
}

.About_whyschooseus .choosecont .visioncard {
    background: linear-gradient(0deg, #fcf2fb, #fff);
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.About_whyschooseus .choosecont .visioncard .icondiv .icon {
    width: 50px;
    height: auto;
    margin-right: 15px;
}

.About_whyschooseus .choosecont .visioncard .name {
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 18px;
    color: #8e1c79;
}

.About_whyschooseus .choosecont .visioncard .content {
    font-weight: normal;
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;

}

@media (min-width:0px) and (max-width:767.98px){
    .About_whyschooseus .choosecont .visioncard .content {
        font-size: 13px;
    }
    .About_whyschooseus .choosecont .visioncard .icondiv .icon {
        width: 25px;
    }
    .About_whyschooseus .choosecont .visioncard .name {
        font-size: 16px;
    }
}

@media (min-width:768px) and (max-width:1200px){
    .About_whyschooseus .choosecont .visioncard .content {
        font-size: 14px;
    }
    .About_whyschooseus .choosecont .visioncard .icondiv .icon {
        width: 25px;
    }
}

@media (min-width:1201px) and (max-width:1400px){
    .About_whyschooseus .choosecont .visioncard .content {
        font-size: 15px;
    }
    .About_whyschooseus .choosecont .visioncard .icondiv .icon {
        width: 25px;
    }
}